@font-face {
  font-family: "Rubik";
  src: url("Rubik-Regular.ttf");
}

html, body, #root {
  width: 100%;
  max-width: 100%;
  position: relative;

  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: column;
  left: 0;
  margin: auto;
  overscroll-behavior: contain;
  padding: 0;
  right: 0;

  font-family: "Rubik",sans-serif;
}

footer {
  margin-top: 30px;
  display: block;
  text-align: center;
  font-size: 0.9em;
  color: gray;
}

.GameIndex {
  padding: 10px;
}

.GameIndexOverflow {
  text-decoration: line-through;
}

footer a {
  color: gray;
}
