.StatsModel {
  min-width: 500px;
  max-width: 100%;
  display: flex;
  overflow-y: hidden;
  pointer-events: all;
  position: fixed;
  top: 0;
  transition: opacity .3s var(--easing);
  z-index: 10;
  margin: 10px auto;
  padding: 20px 20px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  gap: 10px;
  font-size: 1.5rem;
  background-color: #EEE;
  border: 4px solid grey;
}

.StatsModel >* .Tile {
  font-size: 1.5em;
  text-align: center;
}

.StatsModel > header {
  display: flex;
  flex-flow: row nowrap;
  height: 50px;
}

.StatsModel > header button {
  background: transparent;
  border: 0;
  font-size: 2.5rem;
  font-weight: lighter;
  cursor: pointer;
  transition: transform .7s ease-in-out, color .7s ease-in-out;
}

.StatsModel > header button:hover {
  border-radius: 50%;
  background: var(--highlight);
}

.StatsTitle {
  font-size: 2.5rem;
}

.StatsModel>.Row {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.StatsModel>.Row .Index {
  width: 25px;
  padding: 5px;
  text-align: center;
}

.StatsModel>.Row .Bar {
  background: #52AB9F;
  padding: 5px;
  text-align: center;
}

.Divider {
  height: 10px;
}

@media screen and (max-width: 550px) {
  .StatsModel {
    font-size: 1rem;
    width: 85%;
    min-width: 100px;
  }

  .StatsModel header .Title {
    font-size: 2rem;
  }
}

@media (prefers-color-scheme: dark) {
  .StatsModel {
    background-color: #333;
  }
}
