.Help {
  --tile-size: 50px;
  --tile-margin: 3px;

  display: flex;
  pointer-events: all;
  position: fixed;
  top: 0;
  transition: opacity .3s var(--easing);
  z-index: 10;
  margin: 10px auto;
  padding: 20px 20px;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
  gap: 10px;
  font-size: 1.5rem;
  background-color: #EEE;
  border: 4px solid grey;
  max-height: 90%;
  overflow-y: auto;
}

.Content {
  max-height: 100%;
}

.Content > div {
  max-height: 100%;
  margin-top: 10px;
}

.Help >* .Tile {
  font-size: 1.5em;
  text-align: center;
}

.Help > header {
  display: flex;
  flex-flow: row nowrap;
  height: 50px;
}

.Help > header button {
  background: transparent;
  border: 0;
  font-size: 2.5rem;
  font-weight: lighter;
  cursor: pointer;
  transition: transform .7s ease-in-out, color .7s ease-in-out;
}

.Help > header button:hover {
  border-radius: 50%;
  background: var(--highlight);
}

.HelpTitle {
  font-size: 2.5rem;
}

hr {
  border: solid grey;
  border-width: 0px 1px 3px;
  width: 90%;
}

.Example {
}

@media screen and (max-width: 500px),
screen and (max-height: 500px) {
  .Help {
    font-size: 1.3rem;
  }

  .Help > * .Tile {
    font-size: 2em;
  }
}

@media (prefers-color-scheme: dark) {
  .Help {
    background-color: #333;
  }
}
