:root {
  --tile-size: 75px;
  --tile-margin: 10px;
  --size-inc: 10px;
  --sad: #4F5C6A;
  --highlight: #FFFF0033;
  --highlighter: #FFFF00AA;
}

#root {
  margin: 0 auto;
}

.App {
  width: 100%;
  position: relative;
  margin: 10px auto;
  overflow: hidden;
  text-align: center;
  align-items: center;
  align-content: center;
}

header {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row nowrap;
}

.Title {
  font-size: 2.5em;
  padding: 10px;
  flex-grow: 1;
  text-align: center;
}

.Stats {
  width: 50px;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
  padding: 10px;
  cursor: pointer;
}


.Button {
  width: 50px;
  height: 50px;
  padding: 10px;
  cursor: pointer;
}

.Button img {
  border-radius: 50%;
}

.Button img:hover {
  background: var(--highlight);
}

.Button img:active, .Button img:target {
  background: var(--highlighter);
}

.HelpBtn img {
  margin: 4px;
}

.Grid {
  height: calc((var(--tile-size) + var(--tile-margin)) * 5);
  width: calc((var(--tile-size) + var(--tile-margin)) * 5);
  margin: 20px auto;
  position: relative;
  display: block;
}

.Solution {
  margin: 20px auto 0 auto;
}

.Solution > .Title {
  font-size: 1.5em;
}

.Solution > .Grid {
  height: calc((var(--tile-size) / 2 + var(--tile-margin)) * 5);
  width: calc((var(--tile-size) / 2 + var(--tile-margin)) * 5);
  margin: 10px auto 0 auto;
  translate: 10px;
}

.Tile, .empty {
  position: absolute;
  background: grey;
  width: var(--tile-size);
  height: var(--tile-size);
  align-content: center;
  alignment: center;

  font-size: 3.5rem;
  box-sizing: border-box;
  cursor: grab;
  user-select: none;
}

.Solution .Tile {
  width: calc(var(--tile-size) / 2);
  height: calc(var(--tile-size) / 2);
  font-size: 1.5rem;
}

.Tile.sad, .Tile.happy {
  cursor: default;
}

.empty {
  visibility: hidden;
}

.yellow {
  background: #EFC54C;
}

.green {
  background: #52AB9F;
}

.grey {
  background: #C5CFD8;
}

.sad {
  background: var(--sad);
}

.happy[data-col="0"] {
  background: #F9457E;
}

.happy[data-col="1"] {
  background: #FFF37B;
}

.happy[data-col="2"] {
  background: #83F52C;
}

.happy[data-col="3"] {
  background: #3A86FF;
}

.happy[data-col="4"] {
  background: #AE3FCA;
}

.draggable-source--is-dragging {
  opacity: 10%;
  border: 2px solid lightgray;
}

.draggable-mirror {
  border: 4px solid lightgray;
  width: calc(var(--tile-size) + var(--size-inc));
  height: calc(var(--tile-size) + var(--size-inc));
  opacity: 90%;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.4)
}

.draggable--over {
  border: 1px solid black;
}

.Moves {
  font-size: 2em;
}

.Baflas {
  padding: 20px;
}

.Baflas img {
  width: 60px;
  height: 60px;
  padding: 5px;
}

.Share {
  display: flex;
  flex-flow: row nowrap;
  width: 220px;
  margin: 0 auto 10px auto;
  padding: 5px 28px 5px 0;
  background: #52AB9F;
  font-size: 1.4em;
  cursor: pointer;
  user-select: none;
  color: #000;
}

.Share:hover {
  background: #52AB9FDD;
}

.Share:active {
  background: #52AB9FAA;
}

.Share div {
  align-self: center;
}

.Share img {
  width: 30px;
  height: 30px;
  align-self: center;
  margin: 10px;
}

#Copied {
  margin-bottom: 10px;
}

.Timer {
  font-size: 1.1em;
}

#TimerTime {
  font-size: 1.5em;
  font-family: 'monospace';
}

.Announce {
  margin: 20px;
}

@media screen and (max-width: 500px) {
  :root {
    --tile-size: 60px;
    --tile-margin: 5px;
  }

  header {
    max-width: 400px;
  }

  .Tile {
    font-size: 2.5rem;
  }

  .Baflas img {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 350px) {
  :root {
    --tile-size: 40px;
    --tile-margin: 3px;
  }

  header {
    max-width: 300px;
  }

  .Tile {
    font-size: 1.5rem;
  }

  .Button {
    width: 45px;
    height: 45px;
  }

  .Button img {
    width: 40px;
    height: 40px;
  }

  .HelpBtn img {
    width: 32px;
    height: 32px;
    margin: 6px;
  }

  .Baflas img {
    width: 40px;
    height: 40px;
  }
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
  :root {
    --sad: #A6B3C1;
    --highlight: #666666;
    --highlighter: #777777;
  }

  body {
    background-color: #1f1f1f;
    color: #ccc;
  }

  .Tile, .empty {
    background: #424242;
  }

  .grey {
    background: #616161;
  }

  .yellow {
    background: #827717;
  }

  .green {
    background: #33691E;
  }

  .happy {
    color: #333;
  }

  .happy[data-col="0"] {
    background: #F48FB1;
  }

  .happy[data-col="1"] {
    background: #FFF176;
  }

  .happy[data-col="2"] {
    background: #C5E1A5;
  }

  .happy[data-col="3"] {
    background: #81D4FA;
  }

  .happy[data-col="4"] {
    background: #CE93D8;
  }
}
/* End Dark Mode */